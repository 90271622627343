<script setup>
import { inject, onMounted, onBeforeUnmount } from 'vue'
import Modal from './components/Modal.vue'
import Modal2 from './components/Modal2.vue'
import Modal3 from './components/Modal3.vue'
import Sidebar from './components/Sidebar.vue'
import { useMainStore } from './stores/main'
const mainStore = useMainStore()
const appWentOffline = () => { mainStore.online = false }
const appWentOnline = () => { mainStore.online = true }
onMounted(() => {
    window.addEventListener("offline", appWentOffline)
    window.addEventListener("online", appWentOnline)
})
onBeforeUnmount(() => {
    window.removeEventListener("offline", appWentOffline)
    window.removeEventListener("online", appWentOnline)
})
</script>

<template>
    <router-view></router-view>
    <Sidebar v-for="(s,sindx) in mainStore.sidebars" :key="`sidebar-${sindx}`" v-bind="s"></Sidebar>
    <Modal3 v-for="(m,mindx) in mainStore.modals" :key="`$modal-${mindx}`" v-bind="m"></Modal3>
    <Modal2 v-for="(d,dindx) in mainStore.dialogs" :key="`$dialog-${dindx}`" v-bind="d"></Modal2>
</template>

<style lang="scss">
// @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 400;
//     src: url(/fonts/Inter-Regular.woff) format('woff');
// }
// @font-face {
//   font-family: 'InterBold';
//   font-style: normal;
//   font-weight: 600;
//   src: url(/fonts/Inter-SemiBold.woff) format('woff');
// }
#app {
    width: 100vw;
    //height: 100vh;
    height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
    //overflow: hidden;
    max-width: 1920px;
    @media screen and (min-width: 1920px) {
        margin: auto;
        // border-right: 1px solid #e5e7eb;
        // border-left: 1px solid #e5e7eb;
    }
}
.overflow-y-auto {
    overflow-y: auto !important; // auto
    overflow-x: hidden !important;
    &.overflow-y-scroll {
        overflow-y: scroll !important;
    }
    // scrollbar-gutter: stable both-edges;
    // padding-right: 10px;
    &:not(:hover)::-webkit-scrollbar {
        opacity: 0;
    }
    // &:not(:hover) {
    //     -ms-overflow-style: none;
    //     scrollbar-width: none;
    // }
    
    &::-webkit-scrollbar {
        width: 10px; // 6px;
        position: absolute;
        border-radius: 5px;//3px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-thumb {
        color: rgba(0, 0, 0, 0);
        transition: color .25s ease;
        width: 10px; // 6px;
        border-radius: 5px;//3px;
        background-clip: padding-box;
        box-shadow: inset 0 0 0 10px;
        border: 2px solid transparent;
    }
    &:hover {
        &::-webkit-scrollbar-thumb {
            color: rgba(0, 0, 0, 0.3);
            //color: red;
            html.dark & {
                color: rgba(250, 250, 255, 0.5);
            }
        }
    }
    
}

.overflow-x-scroll {
    &.scrollbar-hidden {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }
    }
}

</style>
